.categories {
  .category-list {
    display: block;
    width: 400px;

    .list-group-item {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      .category-display {
        width: 100%;
        padding-right: 1.5rem;

        .text-arabic {
          float: right;
        }
      }

      .actions {
        margin-left: auto;
      }
    }
  }

  form {
    margin-right: 1.25rem;
    display: block;

    input {
      margin-right: 0.5rem;
    }
  }
}

.answers {
  .button {
    height: 40px !important;
  }

  .answer-list {
    animation: showup 0.3s;
  }
}
