.variant-management-wrapper{
    .variant-container{
        display: flex;
        // gap: 20px;
        justify-content: space-between;
        margin-bottom: 15px;
        label{
            font-size: 18px;
            font-weight: 500;
            padding-bottom: 6px;
            padding-top: 5px;
        }
        
        .action-btn{
            padding: 5px 10px;
            border-radius: 19px;
            background-color: #247A81;
            color: #ffffff;
            border-width: 0px;
            width: 240px;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
        }

    }

    .label-title-txt{
        font-size: 14px;
        font-weight: 500;
        color: #A0A0A0;
    }
    .variant-list{
        margin-top: 15px;
        gap: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #F6F7F7;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 10px;

        .image-container{
            height: 60px;
            width: 60px;
            background-color: #F4FCF3;
            align-items: center;
            display: flex;
            cursor: pointer;
            .add-image{
                color: #247A81;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }

        .variant-status{
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 3px;
        }

        .variant-action{
            height: 24px;
            width: 24px;
            cursor: pointer;
        }
        

    }
    .variant-div{
        background: #F6F7F7;
        overflow-x: scroll;
        -ms-overflow-style: none;
  scrollbar-width: none;
    .variant-section{
        display: flex;
        flex-direction: column;
        background: #F6F7F7;
        .media-fields{
            display: flex;
            gap: 10px;
            margin-left: 75px;
            margin-bottom: 15px;
        }
    }

}
.variant-div::-webkit-scrollbar {
    display: none;
  }

    .variant-media-container{
        border-radius: 4px;
        height: 100px;
        width: 100px;
        img{
            height: 100%;
            width: 100%;
        }
    }
}