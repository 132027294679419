.faq-wrapper{
    .faq-container{
        display: flex;
        gap: 20px;
        label{
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 6px;
            padding-top: 5px;
        }
        
        .action-btn{
            padding: 5px 10px;
            border-radius: 19px;
            background-color: #247A81;
            color: #ffffff;
            border-width: 0px;
            width: 240px;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
        }

    }

    .label-title-txt{
        font-size: 14px;
        font-weight: 500;
        color: #A0A0A0;
    }
    .faq-question-and-answer-container{
        margin-top: 25px;
        display: flex;
        gap: 10px;

        .status{
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 3px;
        }

        .action{
            height: 24px;
            width: 24px;
            cursor: pointer;
        }
    }
}