.filter-div{
   .filter-option{
    display: flex;
    align-items: center;
    gap:10px;
    .input-div{
        display: flex;
        align-items: center;
        gap:2px;
        .checkbox{
            cursor: pointer;
        }

    }
   }
}