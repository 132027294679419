@mixin transition-smooth {
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

@mixin transition-slow {
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
}

@mixin hover-fade {
  @include transition-smooth;

  &:hover {
    opacity: 0.7;
  }
}

@mixin hover-card {
  @include transition-smooth;

  &:hover {
    box-shadow: $shadow-2;
  }
}

@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
