.header {
  width: calc(100% - #{$sidebar-width});
  position: fixed;
  left: $sidebar-width;
  z-index: 98;
  background: #fff;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.04);
  padding: 0.2rem 1rem;
  transition: all 0.3s;
  height: 50px;
  animation: showup 0.3s;

  &.wide {
    width: calc(100% - #{$sidebar-small-width});
    left: $sidebar-small-width;
  }

  .page_title {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 2.75rem;
  }

  .navbar {
    float: right;
    margin: 0 !important;
    padding: 0 !important;

    .nav-item {
      list-style-type: none;

      .nav-link {
        padding: 0 0.5rem;

        &.disabled {
          color: rgb(201, 201, 201);
        }
      }

      #account-action {
        background: none;
        color: #888;
        border: none;
        outline: none;
        box-shadow: none;
        font-size: 0.9rem;
        font-weight: $font-weight-normal;

        &:focus {
          background: $valeo-grey;
        }

        .icon {
          margin-left: 6px;
          font-size: 30px;
        }

        .icon-small {
          margin-left: 6px;
          font-size: 20px;
        }

        & + .dropdown-menu {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
