.importErrorContainer{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .3);
    color: #32445b;
    .importErrorsection{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 200px;
        .importErrorContent{
            border-radius: 16px;
        background-color: #FFF;
        padding: 10px;
        width: 480px;
        .crossIcon{
            text-align: end;
            cursor: pointer;
        }
        .errorDiv{
            height: 250px;
            overflow-y: auto;
        }
        }
    }
}
