.manage-bio-markers,
.manage-test,
.manage-package {
  .bio-markers-list-container {
    padding: 15px 30px;
  }
  .custom-check-box {
    display: flex;
    align-items: flex-end;
  }
  .custom-check-box input {
    padding: 0;
    height: 25px;
    width: 25px;
    margin-bottom: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    z-index: 2;
  }

  .custom-check-box label {
    position: relative;
    cursor: pointer;
  }

  .custom-check-box label:before {
    content: "";
    -webkit-appearance: none;
    background-color: #ffffff;
    border: 2px solid $valeo-green;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  .custom-check-box input:checked + label:before {
    content: "";
    background-color: $valeo-green;
  }

  .custom-check-box input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid $valeo-black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .custom-check-box input + label:not(.text) {
    border: 2px solid $valeo-dark;
    border-radius: 50%;
    width: 29px;
    height: 29px;
    overflow: hidden;
  }

  .custom-check-box input:checked + label:not(.text) {
    border: 2px solid $valeo-black;
  }

  .custom-check-box input:checked + .text:after {
    border-color: #fff;
  }

  .custom-check-box input:disabled {
    cursor: default;

    & + label {
      opacity: 0.3;
    }
  }

  .bio-markers-box {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: $valeo-grey;
    margin: 0px 0px 10px 0px;
    padding: 10px;

    &.marker-error {
      border: 1px solid $valeo-red;
      background-color: $valeo-red-light;
    }
  }
  .bio-mark-color-picker {
    border-radius: 4px;
    border: 1px solid #ffffff;
    margin: -4px 0px 0px 0px;
    padding-top: 7px;
    label {
      margin-right: 5px;
    }
    label:before {
      border: 0px;
      content: "";
      background-color: inherit;
    }
    input:checked + label:before {
      content: "";
      background-color: inherit;
    }
    input:checked + label:after {
      top: 5px;
    }
  }
  .colorBox {
    width: 20px;
    height: 20px;
    border: 2px solid $valeo-dark;
  }

  .color-container:not(:last-of-type) {
    margin-right: 1rem;
  }

  .delete-button {
    margin-left: 10px;
  }
  .table-row {
    margin: 0px;
    overflow-x: auto;

    .biomarker-table {
      thead th {
        font-size: 13px;
        padding: 5px 2px;
      }
      tbody td {
        padding: 5px;
        font-size: 15px;
      }
    }
  }
  .package-col {
    width: 25%;
    height: 450px;
    display: inline-block;
    padding: 0 20px 20px 0;

    .package-model {
      border-radius: 0.25rem;
      box-shadow: $shadow-4;
      height: 100%;
      overflow: hidden;

      .image-container {
        width: 100%;
        height: 200px;
        position: relative;

        overflow: hidden;
        .package-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .package-status {
          position: absolute;
          top: 5px;
          right: 5px;
          background-color: #fae5d3;
          color: #d35400;
          padding: 5px 10px;
          text-transform: uppercase;
          font-size: 11px;
          font-weight: bold;
          border-radius: 2px;
          cursor: default;
          &.active {
            color: black;
          }
        }
      }
      .package-details {
        padding: 20px 15px 15px;
        height: calc(100% - 200px);
        position: relative;

        .package-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .description {
          color: $valeo-grey-2;
          font-size: 13px;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 6.9em;
          line-height: 1.8em;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .action-row {
          position: absolute;
          bottom: 10px;
          width: calc(100% - 20px);

          .button-col {
            float: right;

            button {
              margin-top: 0px;
              margin-right: 0;
            }
          }
        }
        .price-col {
          float: left;
          padding-top: 5px;

          .price-label {
            color: $valeo-grey-2;
            font-size: 14px;
          }
          .price-value {
            color: $valeo-dark;
            font-size: 28px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  .product-col {
    width: 25%;
    height: 340px;
    display: inline-block;
    padding: 0 20px 20px 0;

    .package-model {
      border-radius: 0.25rem;
      box-shadow: $shadow-4;
      height: 100%;
      overflow: hidden;

      .image-container {
        width: 100%;
        height: 200px;
        position: relative;

        overflow: hidden;
        .package-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .package-status {
          position: absolute;
          top: 5px;
          right: 5px;
          background-color: #fae5d3;
          color: #d35400;
          padding: 5px 10px;
          text-transform: uppercase;
          font-size: 11px;
          font-weight: bold;
          border-radius: 2px;
          cursor: default;
          &.active {
            color: black;
          }
        }
      }
      .package-details {
        padding: 20px 15px 15px;
        height: calc(100% - 200px);
        position: relative;

        .package-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .description {
          color: $valeo-grey-2;
          font-size: 13px;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 6.9em;
          line-height: 1.8em;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .action-row {
          position: absolute;
          bottom: 10px;
          width: calc(100% - 20px);

          .button-col {
            float: right;

            button {
              margin-top: 0px;
              margin-right: 0;
            }
          }
        }
        .price-col {
          float: left;
          padding-top: 5px;

          .price-label {
            color: $valeo-grey-2;
            font-size: 14px;
          }
          .price-value {
            color: $valeo-dark;
            font-size: 28px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
