.action-container{
    display: flex;
    gap: 30px;
    align-items: center;    
}

.search-btn{
    
    background-color: #247A81;
    border-radius: 20px;
    box-shadow: rgba(31, 33, 36, 0.1) 0px, 1px, 1px, 0px;
    color: #ffffff;
    padding: 16px, 20px;
    border-width: 0px;
    width: 129px;
    text-align: center;
    height: 37px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.export-btn{
    background-color: #FFF;
    padding: 5px 10px;
    color: #000;
    font-weight: 700;
    margin-top: 5px;
    cursor: pointer;
}
.import-btn{
    background-color: #FFF;
    padding: 5px 10px;
    color: #000;
    font-weight: 700;
    margin-top: 5px;
    cursor: pointer;
}
.exportPopUp{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .3);
    color: #32445b;
}
.importPopUp{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .3);
    color: #32445b;
}