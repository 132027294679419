.management_section{
    color: #07141A;

// font-family: Work Sans;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.label-title-txt{
    font-size: 14px;
    font-weight: 500;
    color: #A0A0A0;
}
.media-details-products{
    margin-top: 15px;
    display: flex;
    gap:18px;
    .input-media{
        width:313px;
        height:52px;
    }
}
.single-list{
    color: #07141A;

// font-family: Work Sans;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}