.search {
  display: inline-block;
  min-width: 0px;

  position: relative;
  height: 2.2rem;

  input {
    padding: 0.1rem 1.8rem 0.1rem 2.5rem;
    display: inline-block;
    height: 2.25rem;
    color: #585858;
    background: none;
    transition: all 0.3s;
    border: none;
    border-radius: 2px;
    width: 10;
    position: absolute;
    right: 0;
    cursor: pointer;
    opacity: 0;
    outline: none;
    z-index: 100;

    &:focus,
    &.active {
      background: #fff;
      box-shadow: $shadow-3 !important;
      width: 18rem;
      cursor: text;
      opacity: 1;
    }

    & + .icons {
      position: relative;
      color: #919191;

      .search-icon {
        position: absolute;
        right: 0;
        top: 0.4rem;
        pointer-events: none;
        transition: all 0.3s;
      }

      .clear-icon {
        position: absolute;
        right: 0.5rem;
        top: 0.4rem;
        transition: all 0.2s;
        visibility: hidden;
        width: 1.1rem;

        &:hover {
          color: $valeo-green;
        }
      }
    }

    &:not(:focus):not(.active):hover + .icons {
      .search-icon {
        color: $valeo-green;
      }
    }

    &:focus + .icons,
    &.active + .icons {
      .search-icon {
        right: 15.8rem;
      }

      .clear-icon {
        visibility: visible;
      }
    }
  }
}
