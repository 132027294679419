

.date-div{
   display: flex;
    align-items: center;
    gap:10px;
    .start-date{
        h6{
            margin-top: -15px;
            margin-bottom: 0px;
        }
    }
      .end-date{
        h6{
            margin-top: -15px;
            margin-bottom: 0px;   
        }
    }
}