.spinnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    background: rgba(7, 20, 26, 0.4);
    height: 100vh;
    z-index: 9999999;
}

.spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #247A81;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}