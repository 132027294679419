.marker-container {
  padding: 0.8rem;
  background-color: $valeo-grey;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px;

  .status-div {
    .status {
      background: var(--unnamed-color-cccccc) 0% 0% no-repeat padding-box;
      background: #cccccc 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 4px;
      opacity: 1;
      float: right;
      bottom: 30px;
      position: relative;

      p {
        padding: 4px 18px;
        color: var(--bg);
        text-align: left;
        font: normal normal normal 16px/19px Roboto Condensed;
        letter-spacing: 0px;
        color: #000;
        opacity: 1;
      }
    }
  }
}

.rdt_ExpanderRow {
  margin: 12px 0 12px 0;
  text-align: center;

  .status-div {
    width: 100%;
    padding: 20px;
    text-align: center;
    display: inline-block;
    background: var(--bg) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-cccccc);
    background: #f5f7f5 0% 0% no-repeat padding-box;
    border: 1px solid #cccccc;
    border-radius: 3px;
    opacity: 1;

    .result {
      color: var(--unnamed-color-32445b);
      text-align: left;
      font: normal normal normal 16px/19px Roboto Condensed;
      letter-spacing: 0px;
      color: #32445b;
      opacity: 1;
    }

    .status {
      background: var(--unnamed-color-006666) 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 4px;
      opacity: 1;
      float: right;
      padding: 4px 10px;

      p {
        color: var(--bg);
        text-align: left;
        font: normal normal bold 12px/14px Roboto Condensed;
        letter-spacing: 0px;
        color: #000;
        opacity: 1;
      }
    }

    div[width="450px"] {
      display: inline-block;
      margin: 20px 0 20px 0;
    }
  }
}

.form-row {
  margin-bottom: 15px;

  input {
    border-radius: 3px !important;
    overflow: hidden;

    &[type="file"]::file-selector-button {
      border: unset;
      padding: 0.2em 0.4em;
      border-radius: 0.2em;
      background-color: #a29bfe;
      transition: 1s;
      float: right;
      background: $gradient-green;
      background-color: $valeo-green;
      color: #fff;
    }

    &[type="file"]::-webkit-file-upload-button {
      border: unset;
      padding: 0.2em 0.4em;
      border-radius: 0.2em;
      transition: 1s;
      float: right;
      background: $gradient-green;
      background-color: $valeo-green;
      color: #fff;
    }

    &[type="file"]::file-selector-button:hover {
      opacity: 0.6;
    }

    &[type="file"]::-webkit-file-upload-button:hover {
      opacity: 0.6;
    }
  }

  .dropdown {
    border-radius: 3px !important;
  }
}

.bio-marker-range {
  text-align: center;
}

.dot {
  border-radius: unset !important;
}

.result-submit {
  width: 100%;
}

form {
  .btn {
    float: right;
    padding: unset;

    .MuiSvgIcon-root {
      path {
        color: #32445b;
      }
    }
  }
}

.test-results {
  .survey-links {
    float: right;

    ul {
      list-style-type: none;

      li {
        display: inline-block;
        margin-right: 15px;

        .btn {
          color: #fff;
          font-weight: 400;
        }
      }
    }
  }
}

.rating-badge {
  height: unset !important;

  .rating-icon {
    path {
      color: #ffcc66 !important;
    }
  }
}

#mui-rte-root {
  #mui-rte-toolbar {
    button:last-child {
      position: absolute;
      background: $gradient-purple;
      background-color: $valeo-purple;
      border-radius: 5px;
      color: #fff;
      bottom: -50px;
      right: 30px;
      @include hover-fade;
      &::after {
        content: "Save";
        font-size: 14px;
      }
      span {
        svg {
          font-size: 1.2rem !important;
        }
      }
    }
    button {
      padding: 5px;

      svg {
        font-size: 1.2rem;
      }
    }
  }

  .MUIRichTextEditor-container-23 {
    font-size: 0.8rem !important;
  }

  // .MUIRichTextEditor-editorContainer-26 {
  //   border: 1px solid grey;
  // }
}
label {
  span {
    font-size: 0.7rem;
    font-weight: 600;
  }
}

#mui-rte-editor-container {
  border: none !important;
}

.result-table{
  width: 100%;
}

.result-table-row {
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  min-height: 48px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 0;
  transition: all 0.3s;
  text-align: left;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.12);

  td {
    padding: 4px;
    word-wrap: break-word;
  }
}

.result-table-head {
  th.ascending::after {
    content: "↑";
    display: inline-block;
    margin-left: 1em;
  }

  th.descending::after {
    content: "↓";
    display: inline-block;
    margin-left: 1em;
  }
}
