body,
input,
textarea,
select {
  font-size: 0.9rem !important;
}

h2 {
  font-size: 1.8rem !important;
}

h1,
h2,
h3 {
  font-weight: $font-weight-normal;
}

h5,
h6 {
  font-weight: $font-weight-normal;
}
