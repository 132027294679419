.filter-result{
    display: flex;
    align-items: center;
    margin-left: -10px;
    
    p{
        display: flex;
        justify-content: center;
        align-items: center;
        // width:67px;
        
        color: #07141A;

        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        // margin-right: 10px;
        margin-bottom: 1rem;
        .filter-list-item{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left:10px;
            // gap:4px;
            background-color: #F4FCF3;
            padding:5px 10px;
            border-radius: 20px;
            /* margin: auto; */
            // padding: 5px;
        
        
            span{
                
        
            }
            img{
                width:18px;
                height:18px;
            }
        }
    }
}