.cartPageProducts{
    .selectCountryDiv{
        margin-top: -20px;
    }
    .saveProductBtn{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        width:100px;
        height:36px;
        background-color: #247A81;
        color:white;
        cursor: pointer;

    }
}