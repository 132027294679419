.dealHeader {
    display: flex;
    margin:25px;
    // justify-content: center;
    align-items: center;

    .title {
        color: #07141A;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .addTxt{
        margin-left: 19px;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        display: inline-flex;
        padding: 7px 49px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 19px;
        background: #247A81;
        cursor: pointer;
    }
    .selectCountry{
        align-content: flex-end;
        margin-left: auto;
    }
}

.dealContainer {
    display: flex;
    flex-direction: column;
    background-color: #F1FAF8;
    padding:25px;
    
    .mainHeader{
        display: flex;
        align-items: center;
        gap: 11px;
        .dealNumber{
            color: #07141A;
            font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
        }
        .saveBtn{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            width:100px;
            height:36px;
            background-color: #247A81;
            color:white;
            cursor: pointer;
        }
        .editBtn{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            width:100px;
            height:36px;
            background-color: black;
            color:white;
            cursor: pointer;
        }
        
    }

    .dealDescription {
        display: flex;
        margin-top: 20px;
        gap: 20px;
       
        .iconImage{
            margin-top: -20px;
            .iconDiv{
                height:50px;
                width:40px;
                background-color:#FFF;
            }
            .iconTxt{
                padding-top: 5px;
                padding-left: 2px;
                position: relative;
                top: 5px;
                width: 30px;
                height: 24px;
                border-radius: 19px;
                color: #247A81;
                font-size: 8px;
                font-style: normal;
                font-weight: 600;
                cursor: pointer;
                img{
                    position: relative;
                    width: 22px;
                    left: 0px;
                    top: -10px;
                }
            }
        }
        .bgImage{
            margin-top: -20px;
            // margin-left: auto;
            .imageDiv{
                height:80px;
             width:187px;
             background-color:#FFF;
             .imageTxt{
                position: relative;
    padding-top: 2px;
    padding-left: 14px;
    top: 24px;
    left: 32px;
                width: 83px;
                height: 24px;
                border-radius: 19px;
                background: #247A81;
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                cursor: pointer;
                img{
                    background-color:#FFF; 
                    width: 26px;
                    position: relative;
                    left: -38px;
                    top: -23px;
                }
             }
            }
           
            
        }
    }

    .dealTimer {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        gap: 10px;
    }

    .columnalignactive {

        // height: 32px !important;
        .group {
            .labelTitle {
                color: #A0A0A0;
                // font-family: "Work Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

        }
    }

}