.reviews-wrapper{
    .product-rating-section{
        display: flex;
        // justify-content: center;
        align-items: center;
        gap:10px;
        margin-bottom: 10px;
    }
    select {
        height: 45px;
        width: 68%;
      }
      .label-title-txt{
        font-size: 14px;
        font-weight: 500;
        color: #A0A0A0;
    }
    .reviews-container{
        display: flex;
        gap: 20px;
        label{
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 6px;
            padding-top: 5px;
        }
        
        .action-btn{
            padding: 5px 10px;
            border-radius: 19px;
            background-color: #247A81;
            color: #ffffff;
            border-width: 0px;
            width: 240px;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
        }

    }

    .reviews-container-section{
        margin-top: 25px;
        display: flex;
        gap: 10px;

    .review-row{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .status{
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .action{
        height: 24px;
        width: 24px;
        cursor: pointer;
    }

        
    }
}