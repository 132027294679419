.drag-row-container{
    background: #f1f2f4;
    border-radius: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    .drag-row{
        border: 1px solid;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 6px;
        padding-left: 5px;
        cursor: grabbing;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
    }
}

.select-container{
    margin-top: 15px;
}

.multi-select-wrapper{
    margin-top: 15px;
}

.list-item-container{
    margin-top: 15px;
    .list-item{
        display: flex;
        align-items: center;
        gap: 6px;
        border: 1px solid #E6E7E7;
        text-align: center;
        justify-content: space-between;
        border-radius: 28px;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 12px;
        padding-right: 10px;
        padding-left: 10px;
        margin-top: 10px;
        .list-item-txt{

        }

        img{
            height: 18px;
            width: 18px;
        }
        .cross-icon{
            cursor: pointer;
        }
    }
}