html {
  font-size: 16px;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body {
  color: $valeo-dark;
  background-color:#f0f1f3;
  
}

a {
  text-decoration: none !important;
}

b {
  font-weight: $font-weight-semibold;
}

.page-view {
  width: calc(100% - #{$sidebar-width});
  margin-left: $sidebar-width;
  padding: 2rem;
  padding-top: 5rem;
  position: relative;
  transition: all 0.3s;

  & > div {
    animation: showup 0.6s;
  }

  &.wide {
    width: calc(100% - #{$sidebar-small-width});
    margin-left: $sidebar-small-width;
  }

  .loader {
    position: fixed;
    background: rgba($color: #ffffff, $alpha: 0.8);
    width: calc(100% - #{$sidebar-width});
    height: 100vh;
    left: $sidebar-width;
    top: 0px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;

    &.wide {
      width: calc(100% - #{$sidebar-small-width});
      left: $sidebar-small-width;
    }

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
}

.valeo-loader {
  color: $valeo-green !important;
}

.input-arabic {
  direction: rtl;

  &::placeholder {
    direction: ltr;
  }
}

.alert {
  max-width: 500px;
}

.text-arabic {
  direction: rtl;
  text-align: right;
  margin-bottom: 0;
}

.help-block {
  color: $valeo-red;
}
.rte-mui {
  margin-top: 40px !important;
}

.has-error {
  border-color: $valeo-red !important;
  background-color: $valeo-red-light !important;
}

.notification-top {
  position: fixed;
  top: 85%;
  height: 0px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  opacity: 0;
  transition: all 0.4s;
  margin-left: 45%;
  box-shadow: $shadow-6 !important;

  &:not(.wide) {
    width: calc(52% - #{$sidebar-width});
    left: $sidebar-width;
    z-index: 1200;
  }

  h5 {
    color: #000000;
    margin: 0 auto;
    transition: all 0.1s;
    font-size: 18px;
    // border:1px solid red;
  }

  &.wide {
    width: calc(100% - #{$sidebar-small-width});
    left: $sidebar-small-width;
  }
}

.success-message {
  // background-color: #28a745;
  background-color: #ffffff;
}
.success-bar{
  width:6px;
  height:60px;
  // border:1px solid red;
  background-color: #19A05A;
  border-radius: 5px;
  margin: 5px;
}
.error-bar{
  width:6px;
  height:60px;
  // border:1px solid red;
  background-color: #F0A92E;
  border-radius: 5px;
  margin: 5px;
}
.error-message {
  // background-color: $valeo-red;
  background-color: #ffffff;
}

.show-notification {
  height: 85px;
  opacity: 1;
  // border:1px solid black
}

.text-resize {
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  /* (Number of lines you want visible) * (line-height) */
  line-height: 1.2em;
  text-align: justify;
}

.action-td {
  display: flex;
  width: 100%;
}

.ui.selection.dropdown.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

.action-button {
  display: inline-block;
  margin: 0 0.3rem 0.3rem;
}

::placeholder,
.ui.dropdown:not(.button) > .default.text {
  color: #888 !important;
}

.input-section {
  display: inline-block;

  input,
  .dropdown {
    display: block !important;
  }

  &.input-section-long {
    min-width: 400px;
    margin-right: 5px;
  }
}

form button {
  vertical-align: top !important;
}

.form-image-container {
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  max-width: 200px;
  max-height: 200px;

  .form-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0.25rem;
    object-fit: contain;
  }
}

.form-image-view {
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
  }
}

textarea {
  resize: none;
  height: 5rem;
}

.ui.fluid.multiple.selection.dropdown.form-control {
  height: auto;
}

.card-header-tabs {
  margin-right: 0;
  margin-bottom: -0.75rem;
  margin-left: 0;
}

.button-col {
  button {
    float: right;
    margin-top: 30px;
    margin-right: 15px;
  }

  button:first-child {
    margin-right: 0px;
  }
}

.card {
  box-shadow: $shadow-3 !important;
  border: none !important;
  margin-bottom: 1.5rem;

  .card-body {
    padding: 20px;
    background-color: #fff;

    & + .nav-tabs {
      padding-top: 0;
    }
  }
 
  
}


.nav-tabs {
  border-bottom: 1px solid #dee2e6 !important;
  padding: 10px 25px 0;

  .nav-link {
    border: none;
    font-size: 0.9rem;
    color: $valeo-grey-2;
    transition: all 0.3s;
    border-bottom: 4px solid rgba(0, 0, 0, 0);
    font-weight: $font-weight-semibold;

    &.active {
      color: $valeo-green;
      border-bottom: 3px solid $valeo-green;
    }
  }
}

.table {
  margin-bottom: 0;
}

.ui.selection.dropdown .menu > .item {
  font-size: 0.9rem;
}

.table-responsive {
  table {
    td {
      white-space: nowrap;
    }
  }

  select {
    min-width: 160px;
  }
}

td {
  font-size: 0.85rem !important;
}

.badge {
  padding: 0.2rem 0.6rem;
  font-weight: $font-weight-normal;
  font-size: 0.6rem;
  width: 84px;
  height: 24px;
  text-align: center;
  pointer-events: none;
  // display: flex;
  justify-content: center;
  align-items: center;

  .MuiSvgIcon-root {
    width: 1.2em;
    height: 1.2em;
    font-size: 0.8rem !important;
  }

  &.no-background {
    background: none !important;
    font-size: 0.8rem;
  }

  &.auto-width {
    width: auto;
  }

  &.badge-circle {
    font-weight: $font-weight-bold;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-right: 3px;
    margin-left: -4px;
    height: 1.2rem;
  }

  &.badge-success {
    color: #28a745;
    background-color: #d4edda;
  }

  &.badge-secondary {
    color: #566573;
    background-color: #eaecee;
  }

  &.badge-wide {
    width: 150px;
  }

  &.badge-green {
    color: #16a085;
    background-color: #e8f6f3;
  }

  &.badge-violet {
    color: #8e44ad;
    background-color: #f4ecf7;
  }

  &.badge-danger {
    color: $valeo-red;
    background-color: $valeo-red-light;
  }

  &.badge-warning {
    background-color: #fae5d3;
    color: #d35400;
  }

  &.badge-blue {
    background-color: #e6f3fc;
    color: #0984e3;
  }

  &.badge-skyblue {
    background-color: #e6fafa;
    color: #00a5a1;
  }

  &.badge-gray {
    background-color: #f5f7f5;
    color: #32445b;
    pointer-events: unset;

    a {
      text-decoration: underline !important;
      color: #007bff;
    }
  }
}

.btn {
  font-size: 0.85rem;
  font-weight: $font-weight-semibold;
}

.valeo-link {
  color: $valeo-green;

  &:hover {
    color: $valeo-blue;
  }
}

.form-buttons {
  margin-top: 20px;

  button:first-of-type {
    margin-right: 10px;
  }

  &.inline-buttons {
    margin-top: 2em;

    button:first-of-type {
      margin-left: 8px;
    }
  }
}

.form-row {
  margin-left: 0;
  margin-right: 0;
}

.form-group.half-width {
  max-width: 50%;
}

.action-menu {
  .dropdown-toggle {
    color: #888;
    outline: none;
    font-size: 0.9rem;
    background: none;
    padding: 5px;
    border-radius: 50px;
    border: none;
    box-shadow: none !important;

    &:focus,
    &:active,
    &:hover {
      background: $valeo-grey !important;
      color: $valeo-green !important;
      box-shadow: $shadow-3 !important;
    }

    &:after,
    &:before {
      content: none;
    }
  }
}

.select-menu {
  position: relative;

  label {
    position: absolute;
    top: -0.7rem;
    white-space: nowrap;
    font-size: 0.7rem;
    left: 0;
  }

  .valeo-dropdown {
    .dropdown-toggle {
      color: #888;
      outline: none;
      font-size: 0.9rem;
      background: none;
      padding: 5px;
      border-radius: 50px;
      border: none;
      box-shadow: none !important;

      &:focus,
      &:active,
      &:hover {
        background: $valeo-grey !important;
        color: $valeo-green !important;
      }
    }

    .dropdown-item {
      padding: 0.5rem 2.5rem 0.4rem 0.5rem;
    }

    .dropdown-header {
      margin-left: 0.8rem;
    }

    .dropdown-item:not(.active) {
      padding-left: 3rem;
    }
  }
}

.valeo-dropdown {
  .dropdown-menu {
    font-size: 0.9rem;
    border: none;
    box-shadow: $shadow-4;
    min-width: 8.5rem;
    animation: showup 0.15s;

    .dropdown-header {
      padding: 0.5rem 2rem 0.4rem 1.2rem;
    }

    .dropdown-divider {
      margin: unset;
    }

    .dropdown-item {
      font-size: 0.9rem;
      transition: all 0.3s;
      padding: 0.5rem 2rem 0.4rem 1.2rem;
      color: rgb(82, 82, 82);
      font-weight: $font-weight-semibold;

      .notification {
        .notification-bell {
          display: inline-block;
          height: auto;
          vertical-align: top;
        }

        .notification-detail {
          display: inline-block;
          height: auto;
          margin-left: 20px;
          vertical-align: top;
        }
      }

      svg {
        margin-right: 0.6rem;
        margin-left: -0.3rem;
        width: 1.5rem;
      }

      .icon-small {
        width: 1.1rem;
        margin-right: 0.7rem;
        margin-left: 0rem;
      }

      &.active {
        color: $valeo-green;
        background: none;
        cursor: default;
      }

      &.disabled {
        opacity: 0.4;
      }
    }

    &:not(.filter-menu) .dropdown-item:not(.active) {
      &:hover {
        background: $valeo-green-light;
        color: $valeo-green;
      }

      &.danger {
        color: $valeo-red;

        &:hover {
          background: $valeo-red-light;
        }
      }

      &.warning {
        color: #d35400;

        &:hover {
          background-color: #fbeee6;
        }
      }

      &.success {
        color: #28a745;

        &:hover {
          background: #eaf6ec;
        }
      }

      &.green {
        color: $valeo-green;

        &:hover {
          background: $valeo-green-light;
        }
      }
    }

    &.filter-menu {
      .dropdown-item {
        padding: 0.3rem 2rem 0.3rem 1.2rem;

        &:hover {
          background: none;
        }
      }
    }
  }

  .no-icon {
    .dropdown-item {
      padding: 0.35rem 1.6rem;

      svg {
        display: none;
      }
    }
  }

  &.dropdown-bordered {
    .dropdown-menu {
      border: 1px solid rgb(216, 216, 216);
    }
  }
}

.edit-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  transition: all 0.3s;
  font-size: 0.8rem !important;

  &:hover {
    fill: $valeo-green;
  }
}

.page-header {
  // width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    margin-left: auto;

    .action-item {
      display: flex;
      align-items: center;
      justify-content: center;

      .new-user {
        margin-left: 10px;
      }
      .new-user > i {
        margin-right: 10px;
      }

      &:not(:last-of-type) {
        margin-right: 0.65rem;
      }

      .text-button {
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        background: rgba(0, 0, 0, 0);
        color: $valeo-green;
        outline: none;
        box-shadow: none;
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
        }

        &.button-red {
          color: $valeo-red;
        }
      }

      .valeo-dropdown {
        display: inline-block;

        .dropdown-menu {
          margin-top: 0.8rem;
        }
      }
    }
  }
}

.form-control {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid rgb(216, 216, 216);
  border-radius: 0 !important;
  padding: 0.375rem 0.8rem;

  &:not(:read-only):focus {
    border: 1px solid $valeo-blue;
    background: $valeo-blue-light;
  }

  &:not(:read-only):not(:focus):hover {
    border: 1px solid rgb(197, 197, 197);
  }
}

form,
.modal-body {
  input.form-control,
  select.form-control,
  .dropdown.form-control {
    height: auto;
  }

  textarea.form-control {
    min-height: 90px;
  }
}

.modal-content {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border: none;
}

.input-small {
  max-width: 50%;
}

.ui.disabled.search {
  background-color: #e9ecef;
  opacity: 1;
  border: 1px solid #d8d8d8;
}

.ui.selection.dropdown {
  min-width: 0;
}

.checkbox {
  transition: all 0.3s;
  cursor: pointer;
  font-weight: $font-weight-semibold;

  &.checked,
  &:hover {
    color: $valeo-green;
  }

  svg {
    margin-right: 0.3rem;
  }
}

button {
  outline: none !important;
}

.alert-box {
  width: 100%;
  text-align: center;
  color: $valeo-grey-2;

  &.error {
    color: $valeo-red;
  }

  svg {
    display: block;
    margin: 0 auto;
    font-size: 2rem;
    margin-bottom: 0.8rem;
  }
}

.table-header {
  font-size: 1rem;
}

.rdt_TableCell {
  line-height: 20px !important;

  .MuiSvgIcon-root {
    font-size: 0.8rem;

    path {
      color: #ffcc66;
    }
  }

  select {
    font-size: 13px !important;
    height: auto;
    width: 150px;
  }
}

.rdt_TableRow {
  padding: 8px 0;
  transition: all 0.3s;

  &:hover {
    background: rgb(238, 238, 238) !important;
  }
}

.rdt_TableHeader {
  padding: 20px 16px 5px 24px !important;
  min-height: 0 !important;
}

.detailed-view {
  padding: 1rem;
  padding-left: 4rem;
  border-top: 1px solid #d8d8d8;
}

.modal-backdrop-updated {
  opacity: 0 !important;
}

.user-data-modal {
  .modal-dialog {
    margin: 0 !important;
    float: right;
    .modal-content {
      height: 100%;
      min-height: 100vh;
      .modal-header {
        position: sticky;
        top: 0;
        z-index: 1020;
        background-color: #fff;
        align-items: center;
        .modal-title {
          display: flex;
          width: 100%;
          .user-title {
            width: 80%;
            font-size: 18px;
            font-weight: 400;
            align-items: center;
            display: flex;
            color: #000000;
          }
          .user-data-save {
            width: 20%;
            font-weight: bold;
            color: #fff;
            background: #008000;
            &:hover {
              border: 1px solid #008000;
              color: #008000;
              background: #fff;
            }
          }
          .user-data-toggele {
            width: 20%;
            font-weight: bold;
            margin-right: 10px;
            color: #fff;
            background: #f11111;
            &:hover {
              border: 1px solid #f11111;
              color: #f11111;
              background: #fff;
            }
          }
        }
      }
      .modal-body {
        .nav-tabs {
          border-bottom: 0px !important;
          padding: 0px;
          justify-content: space-between;
          .nav-link {
            padding: 0.5rem 2rem;
          }
          .nav-link.active {
            color: #000000;
            border-bottom: 3px solid #000000;
          }
        }
        .nav-link.active {
          color: #000000;
          border-bottom: 3px solid #000000;
        }
        .nav-link.disabled {
          opacity: 0.6;
          pointer-events: auto;
          cursor: not-allowed;
        }
        .tab-content {
          #controlled-tab-example-tabpane-transactions {
            .rdt_TableHeadRow,
            .rdt_TableRow {
              justify-content: space-between;
            }
          }
        }
      }
      .modal-footer {
        .user-data-toggele {
          width: 20%;
          font-weight: bold;
          margin-right: 10px;
          color: #fff;
          background: #f11111;
          &:hover {
            border: 1px solid #f11111;
            color: #f11111;
            background: #fff;
          }
        }
      }
    }
  }
  .modal-save-btn {
    width: 20%;
    font-weight: bold;
    background: #CEE741;
  }
  .modal-delete-btn {
    width: 20%;
    font-weight: bold;
    background: #F24726;
  }
}

.form-group-container {
  display: flex;
  .form-group {
    padding: 0px 15px;
    width: 50%;
    .user-data-input {
      height: auto !important;
    }
    .error {
      color: #e6787a;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
.form-content {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid #32445b;
  text-align: center;
  margin: 30px 0px;
}
.form-content > span {
  font-size: 22px;
  background-color: #fff;
  padding: 0 40px;
  color: #32445b;
}

.number-field {
  display: flex;
}
.user-input-container {
  position: relative;
  .flex {
    display: flex;
  }
  .dropdown {
    opacity: 1 !important;
  }
  .editable {
    background: #e9ecef !important;
    color: #000000;
    cursor: not-allowed;
    border: 1px solid #d8d8d8 !important;
  }
  .non-editable {
    background: #ffffff !important;
    color: #495057;
    cursor: default;
    border: 1px solid #d8d8d8 !important;
  }
  .user-data-edit-icon {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    outline: none;
    border: 1px solid #e9ecef;
    background: white;
    line-height: 33px;
    height: -webkit-fill-available;
    width: 25px;
    text-align: center;
    vertical-align: middle;
  }
  .editable-phone {
    .react-tel-input {
      .form-control {
        background: #e9ecef !important;
      }
    }
  }
  .non-editable-phone {
    .react-tel-input {
      .form-control, .flag-dropdown {
        background: #ffffff !important;
      }
    }
  }
  .react-tel-input {
    .form-control {
      width: 100%;
      height: 35px !important;
      background: #e9ecef!important;
    }
  }
  .dob {
    height: 40px !important;
    width: 93%;
  }
  // .input-calendar {
  //   .input-calendar-field {
  //     width: 80%;
  //     padding: 8px;
  //     border-radius: 0px;
  //     border: 1px solid #d8d8d8 !important;
  //   }
  //   .calendar-icon {
  //     transform: none;
  //   }
  //   .input-calendar-wrapper {
  //     left: 10px;
  //   }
  // }
}

.enable-question-facts {
  width: 40%;
  margin-left: 30px;
  margin-top: 25px;
  justify-content: space-evenly;
}

.question-fact {
  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: green;
    background-color: green;
  }
}

.image-area {
  position: relative;

  .remove-image {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 8em;
    padding: 1px 8px 2px;
    border: 3px solid #fff;
    font: 150 sans-serif;
    background: #6C757D;
    color: #FFF;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
  }

  .remove-image:hover {
    background: #DC3545;
    padding: 1px 8px 2px;
    top: -11px;
    right: -11px;
  }

  .remove-image:active {
    background: #DC3545;
    top: -10px;
    right: -11px;
  }
}

.OperationsInput {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.labInfoForm{
  border:1px solid red;
  margin-left: 30px;
}


.rating-container {
  .star {
    font-size: 18px;
    color: #f39c12;

    &.grey {
      color: #b1b1b1;
    }
  }

  .rating-text {
    margin-left: 5px;
    color: #636363;
  }
}

.icon-green {
  color: #16a085;
}

.addon-box {
  color: #16a085;
  background-color: #e8f6f3;
  padding: 10px 20px;
  max-width: 500px;
  min-width: 300px;
  display: inline-block;
  border: 1px solid #16a085;
  margin-right: 10px;
  margin-bottom: 30px;

  .heading {
    font-weight: $font-weight-semibold;
  }
}

.addon-plans-box {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: $valeo-grey;
  margin: 0px 0px 10px 0px;
  padding: 10px;

  &.marker-error {
    border: 1px solid $valeo-red;
    background-color: $valeo-red-light;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
    width: 800px;
  }
}

@keyframes showup {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

div.img-input-wrapper {
  position: relative;

  input {
    position: absolute;
    left: 0;
    font-size: xxx-large;
    top: 0;
    height: 200px;
    background-color: red;
    border-radius: 50%;
    width: 200px;
    opacity: 0;
    cursor: pointer;
  }

  .circle-img {
    object-fit: cover;
    width: 200px;
    height: 200px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #707070;
  }
}

div.gray-box {
  background: $valeo-grey;
  padding: 20px;
  margin-bottom: 10px;

  div {
    display: flex;
    justify-content: space-between;
  }
}

.view-pdf {
  color: #32445b;

  label {
    color: #32445b;

    &:hover {
      text-decoration: underline;
    }
  }
}

.list {
  // display: inline-block;
  list-style: none !important;
  margin-top: 10px;
}
.deleteItem {
  user-select: none;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  font-size: 1em;
  padding: 0.35714286em 0.78571429em;
  margin: 0.14285714rem 0.28571429rem 0.14285714rem 0;
  box-shadow: 0 0 0 1px #e8e8e8 inset;
}
.dropDown_width {
  width: 70px !important;
}
.product_dropdown {
  width: 200px;
}
.dropDown_product_cat {
  width: 270px !important;
}

.closeNotification {
  position: absolute;
  right: 25px;
  top: 35px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.closeNotification:hover {
  opacity: 1;
}
.closeNotification:before,
.closeNotification:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 15px;
  width: 2px;
  background-color: #333;
}
.closeNotification:before {
  transform: rotate(45deg);
}
.closeNotification:after {
  transform: rotate(-45deg);
}

.toggle-switch {
  position: relative;
  width: 45px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  transform: scale(0.9);

  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 30px;
      padding: 0px;
      line-height: 30px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "";
      text-transform: uppercase;
      padding-left: 30px;
      background-color: #00c4d7;
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: "";
    text-transform: uppercase;
    padding-right: 0px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 20px;
    height: 20px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      left: 13px !important;
    }
  }
}

.modal-backdrop-updated {
  opacity: 0 !important;
}

.slot-text {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  font-size: 12px;
  padding: 5px 5px;
  margin: 0.14285714rem 0.28571429rem 0.14285714rem 0;
  box-shadow: 0 0 0 1px #222426 inset;
  vertical-align: baseline;
  margin: 0 0.14285714rem;
  background-color: #e8e8e8;
  background-image: none;
  text-transform: none;
  border: 0 solid transparent;
  font-weight: 700;
  border-radius: 0.28571429rem;
  transition: background 0.1s ease;
}

.delete.icon {
  cursor: pointer;
  margin-right: 0;
  margin-left: 0.5rem;
  font-size: 0.92857143em;
  opacity: 0.5;
  transition: background 0.1s ease;
}

.icon {
  width: auto;
  margin: 0 0.75rem 0 0;
}

.slot-text i.icon {
  display: inline-block;
  opacity: 1;
  margin: 0 0.25rem 0 0;
  width: 1.18rem;
  height: 1rem;
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  text-align: center;
  speak: none;
  backface-visibility: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-search {
  background-color: #384760;
  color: white;
}

.noteText {
  width: 85%;
  margin: auto;
  top: 10px;
}
