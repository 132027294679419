.coupon-generator {}

.modal-content {
    .nav-pills {
        .nav-item {
            .nav-link {
                &.active {
                    background-color: $valeo-blue;
                }

                &:not(.active) {
                    color: $valeo-dark;
                }
            }
        }
    }

    .tab-content {
        .tab-pane {
            .button {
                margin: 0 15px !important;
                border-radius: 3px;
                background-color: $valeo-purple;
            }

            input[type="checkbox"] {
                margin: 0 5px;
            }
        }
    }
}