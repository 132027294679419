.slider {
    .table-img {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

    .card {
        .card-body {
            p {
                font-weight: $font-weight-bold;
            }
        }
    }

}

.how-it-works {
    .table-img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }

    .card {
        .card-body {
            .p-head {
                font-weight: $font-weight-bold;
            }
        }
    }
}

.modal {
    .modal-dialog {
        .modal-content {
            .modal-body {
                .slider-col {
                    position: relative;
                    display: block;
                    width: 100%;
                    text-align: center;

                    .work-section {
                        padding: 10px;
                    }

                    label {
                        float: left;
                    }

                    .img-input-wrap {
                        padding: 10px;

                        .upload-image-label {
                            float: unset;
                            left: 10px;
                            position: absolute;
                            top: 61%;
                        }

                        .img-wrapper {
                            height: 140px;
                            background: #fafafa;
                            border-radius: 8px;
                            padding: 10px;
                            border: 2px dashed #cdcdcd;

                            img {
                                height: 50px;
                                width: auto;
                                margin-top: 8%;
                                object-fit: contain;

                            }

                            .img-banner {
                                width: 100%;
                                height: 120px;
                                object-fit: cover;
                                object-position: center;
                                margin-top: unset;
                            }
                        }
                    }
                }
            }

            .modal-footer {
                position: relative;
                padding: 0.6rem;


                input {
                    text-align: center;
                    display: none;
                }

                label {
                    position: absolute;
                    bottom: 2px;
                    left: 8px;
                    background: $gradient-green;
                    background-color: $valeo-green;
                    padding: 5px 12px;
                    border-radius: 8px;
                    color: #fff;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }

                .remove-button {
                    position: absolute;
                    bottom: 10px;
                    left: 80px;
                    border: unset;
                    background: transparent;
                    border-radius: 8px;
                    padding: 5px 8px;
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: #dc3545;

                    &:hover {
                        background: #fbe9eb;
                    }

                }


                .remove {
                    left: 130px
                }
            }
        }
    }
}

.delete-btn {
    border: none;
    background: transparent;

    font-size: 20px;

    .MuiSvgIcon-root {
        width: 1.2em;
        height: 1.2em;
        font-size: 22px;

        path {
            color: #888;
        }
    }
}

.rdt_Table {
    text-align: left;

    .rdt_TableHead {
        .rdt_TableHeadRow {
            .rdt_TableCol {
                .rdt_TableCol_Sortable {
                    div {
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .rdt_TableRow {
        .drag-handle {
            cursor: move !important;
        }

        .MuiSvgIcon-root {
            width: 1.2em;
            height: 1.2em;
            font-size: 22px;

            path {
                color: #888;
            }
        }
    }
}
.productpagebackgroundcolor{
    background-color:#f1f2f4;
}
.producttable{
    background-color: #ffffff;
}