.pricing-on-country-level-wrapper{
    select {
        height: 45px;
      }

      .label-title-txt{
        font-size: 14px;
        font-weight: 500;
        color: #A0A0A0;
    }
      
    .pricing-header-container{
        display: flex;
        gap: 20px;
        label{
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 6px;
            padding-top: 5px;
        }
        
        .action-btn{
            padding: 5px 10px;
            border-radius: 19px;
            background-color: #247A81;
            color: #ffffff;
            border-width: 0px;
            width: 240px;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
        }

    }

    .pricing-list-container{
        overflow-x: scroll;
        -ms-overflow-style: none;
  scrollbar-width: none;
        background-color: #f1f2f4;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 18px;
        box-sizing: border-box;
        margin: 5px;
        margin-top: 20px;
        .price-list-row-container{
            display: flex;
            flex-direction: row;
            column-gap: 18px;
            box-sizing: border-box;
            align-items: center;
            .status-container{
                display: flex;
                flex-direction: column;
            }
    
            .action{
                height: 24px;
                width: 24px;
                cursor: pointer;
            }
    
        }
        .seo-container{
            margin-top: 10px;
            .header-txt{
                font-size: 12px;
                font-weight: 600;
            }
            .seo-input-container{
                margin-top: 20px;
            }
            .columnalignactive{
                margin-top: 10px;
            }
        }
        .variant-price-container{
            margin-top: 15px;
            .add-variant-btn{
                background-color: #247A81;
                padding-top: 5px;
                padding-bottom: 5px;
                width: 99px;
                color: white;
                text-align: center;
                border-radius: 19px;
                cursor: pointer;
            }
            .list-of-variant{
                margin-top: 30px;
                .variant-price-row-container{
                    background-color: white;
                    display: flex;
                    gap: 15px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    padding-left: 15px;
                    padding-right: 15px;
                    border-radius: 19px;
                    margin-top: 15px;
                    .action{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 4px;
                        cursor: pointer;
                        img{
                            height: 18px;
                            width: 18px;
                        }
                    }
                }
            }
        }
    }
    .pricing-list-container::-webkit-scrollbar {
        display: none;
      }

}