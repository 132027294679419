@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  background-color: #f1f2f4;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  background-color: #f0f1f3;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: always;
}

* {
  font-family: "-apple-system", BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, "SF Mono", Consolas,
    "Liberation Mono", Menlo, monospace;
}

.separator {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #000;
  line-height: 0.1em;
  margin: 20px 10px 20px 10px;
}

.separator span {
  font-weight: 500;
  background: #fff;
  padding: 0 10px;
  border: 1px solid black;
  border-radius: 10;
}

.text-overflow-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.placeholder {
  color: lightgrey;
  font-style: italic;
}

.fullpage {
  display: flex;
  flex-direction: row;

  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #f1f2f4;
  box-sizing: border-box;
  margin: 10px;
}

.titlefield {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90%;
  border: 2px solid black;
  background-color: #ffffff;
  justify-content: center;
  box-sizing: border-box;
}

.inputfieldsize {
  width: 100%;
  height:3em;
  padding: 0px;
}
.ar-inputfieldsize {
  width: 100%;
  height:3em;
  padding: 0px;
  text-align: right;
}
.disabled-button{
  padding: 10px 30px;
  border-radius: 20px;

  color:black;
  border-width: 0px;
  background-color:grey;

}

.editorsize {
  height: 20%;
  width: 90%;
}

.mediasize {
  height: 10rem;
  width: 100%;
  padding: 0px;
}

.firstblock {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px;
  margin: 5px;
  width: 70%;
}

.firstblockcollection {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px;
  margin: 5px;
  width: 70%;
}

.secondblock {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px;
  margin: 5px;
  width: 25%;
}

.notetextcolor {
  color: #a0a0a0;
}

.alignlabel {
  display: flex;
  flex-direction: row;
}

label[for="per"] {
  border: 1px solid #e6e7e7;
  border-left-width: 0px;
  padding: 10px;
  color: #9c90a1;
  border-radius: 0px 8px 8px 0px;
  line-height: 5px;
}

#txt {
  border: none;
  outline: none;
}

label[for="txt"] {
  border: solid 1px black;
  padding: 0 0 0 5px;
  color: #9c90a1;
}

.mediadashedarea {
  height: 90%;
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.alignside {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.selectitemsize {
  width: 10rem;
}

.publishalign {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.linestyle {
  background-color: black;
  height: 1px;
  padding: 0px;
  margin: 0px;
  border: none;
}

.colorchange {
  background-color: red;
}

.selectcolorchange {
  border-color: #c7cbd1;
}

.titleeditor {
  padding: 16px;
}

select {
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid #e6e7e7;
}

.divproduct {
  padding: 16px;
  border: 2px solid #e4e7ea;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #f9fafb;
  box-sizing: border-box;
}

.selectweight {
  padding: 16px;
  border: 2px solid #e4e7ea;
  border-bottom: none;
  background-color: #ffffff;
  box-sizing: border-box;
}

.checkboxshipping {
  padding: 16px;
  border: 2px solid #e4e7ea;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ffffff;
  box-sizing: border-box;
}

.digitalproduct {
  padding: 16px;
  border: 2px solid #e4e7ea;
  border-radius: 8px;
  background-color: #ffffff;
  box-sizing: border-box;
}

.textcolor {
  color: #a0a0a0;
}

.fontcolorButton {
  color: #457bc6;
  border: none;
  background-color: #ffffff;
}

.productpage {
  background-color: #f1f2f4;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.rightextreme {
  display: flex;
  flex-direction: row-reverse;

  box-sizing: border-box;
}


.greenbackground {
  background-color: #247A81;
  border-radius: 20px;
  box-shadow: rgba(31, 33, 36, 0.1) 0px, 1px, 1px, 0px;
  color: #ffffff;
  padding: 20px, 30px;
  min-width: 36px;
  min-height: 36px;
  border-width: 0px;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
}

.sizechange {
  box-sizing: border-box;
  max-width: 50%;
}

#prodcode {
  display: hide;
}

.flexchange {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.borderless {
  outline: none;
}

.aligninrow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.endsofdiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.saveback {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.alignrowedit {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.signup {
  background-color: #98e0fc;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.shopifysignup {
  display: flex;
  flex-direction: column;
  width: 80%;

  background-color: #ffffff;
  row-gap: 20px;
  padding: 2.5rem 2.5rem;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.Namestyle {
  font-size: large;
  font-weight: 300;
  color: #000;
}

.createsen {
  color: #202223;
  font-size: 1.5rem;
  line-height: 1.2em;
  margin-bottom: 0.25rem;
}

.subhead {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: #6d7175;
  line-height: 1.5rem;
  text-transform: none;
  margin-bottom: 1.25rem;
}

.emailblock {
  display: flex;
  justify-content: center;
  border: 2px solid #8c9196;
  border-radius: 0.5rem;
  padding: 19px 24px;
  margin: 10px 0px;
}

.inputpadding:focus {
  border: 1px solid blue;
}

.flextwocolums {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.inputpadding {
  padding: 13px;
  border-radius: 5px;
  border: 1px solid #c0c2c5;
  width: 100%;
}

.lablestyle {
  color: #202223;
}

.sentence {
  color: #6d7175;
}

.emailcontinue {
  background-color: #008060;
  font: 16px;
  width: 100%;
  color: #ffffff;
  margin: 15px 0px;
  padding: 11px 24px;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  border-width: 0;
}

.alignEnd {
  display: flex;
  justify-content: space-between;
}

.passwordBorder{
 border:none;
 outline: none;
}
.passwordRight{
border:none;
cursor: pointer;
}
.borderparent{
  border:1px solid #c0c2c5;
  border-radius: 5px;
  display: flex;
  justify-content: center;
 align-items: center;
 padding: 5px;
}
.shopifysignin {
  background-color: #98e0fc;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.imagefilesize {
  height: 50%;
  width: 50%;
}

.datatablenumber {
  border-radius: 0.25rem;
  border: 1px solid #959a9e;
  border-spacing: 2px;
}

.datatablenumber:focus {
  border: 2px solid lightblue;
}

.editbtn {
  border-radius: 0.25rem;
  border: 1px solid #959a9e;
  border-width: 1px;
  color: #1f2124;
  padding: 5px 15px;
}

.newvaraintbutton {
  padding: 5px 10px;
  border-radius: 19px;
  background-color: #247A81;
  color: #ffffff;
  border-width: 0px;
}
.alignrowcenter{
  display:flex;
  justify-content: center;
  align-items: center;
  
}

.pricesize{
  width:10%;
  box-sizing: border-box;
}
.variantstyle {
  background-color: #f1f2f4;
  padding: 8px;
  border-radius: 8px;
  margin: 10px;
  align-items: center;
}

.countryblock {
  background-color: #f1f2f4;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  margin: 5px;
}

.variantimage {
  height: 40px;
  width: 40px;
}
.rowextreme{
  display:flex;
  justify-content: space-between;
}

.alignrow {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  box-sizing: border-box;
}
.alignrowCountry{
  display: flex;
  flex-direction: row;
  column-gap: 25px;
  box-sizing: border-box;
}

.imageblock {
  border: 1px solid #f1f2f4;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}

.columnalign {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.rightinputradius {
  background: #ffffff;
  border: 1px solid #e6e7e7;
  border-radius: 8px 0px 0px 8px;
  border-right-width: 0px;
  padding: 2px;
}

input {
  background: #ffffff;
  border: 1px solid #e6e7e7;
  border-radius: 8px;
  padding: 6px;
}

textarea {
  background: #ffffff;
  border: 1px solid #e6e7e7;
  border-radius: 8px;
  padding: 3px;
}

input[type="text"]:focus {
  background: #ffffff;
  border: 2px solid #247a81;
  outline: none !important;
  border-radius: 0px;
}
input[type="button"]:active{
  background-color: #457bc6;
}

textarea:focus {
  background: #ffffff;
  border: 2px solid #247a81;
  outline: none !important;
  border-radius: 0px;
}

.alignrowwrap{
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.gridalign{
  display:flex;
  flex-direction:row;
  height:400px;
  width:600px;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
 
}

.imagecollectionblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input[type="number"]:focus {
  /* Rectangle 157376 */
  background: #ffffff;
  border: 2px solid #247a81;
  outline: none !important;
  border-radius: 0px;
}

.columnalignactive {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.faqblock {
  margin-bottom: 10px;
}

.modallabel {
  font-family: "Poppins",sans-serif;
  margin:0px 0px 8px;
  font-size: 14px;
}

.modalinput{
  margin:0px 0px 16px;
  padding: 0px 15px;
}
.variantpriceblock {
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.prodselect {
  margin: 10px;
}

.group {
  position: relative;
}

.group>label {
  padding: 0 0.2em;
  position: absolute;
  top: -0.7em;
  left: 1em;
  background-color: white;
}

.group>input {
  width: 100%;
  padding: 0.8em;
  border-radius: 0.5em;
  /* border: 2px solid #247a81; */
  outline: none;
  
}
.group>.disabled-input {
  background-color: #d4d4d5
}
.group>enable-input{
background-color: #ffffff;;
}

.group>textarea {
  padding: 0.8em;
  border-radius: 0.5em;
  /* border: 2px solid #247a81; */
  outline: none;
}

.radiorow {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.alignrowcoupon {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.alignrowpopup {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
}
.namesize{
  width:50%;

}

.columnaligncoupon {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background-color: #f4fcf3;
  padding: 8px;
  border-radius: 8px;
}

.columnaligncouponselect {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.applymargin {
  margin: 25px;
}

.imageStyle {
  background: #f4fcf3;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.imageviewstyle{
  background: #f4fcf3;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height:20%;
  width:20%;
  display: flex;
}
.imageViewstyle{
  background: #f4fcf3;
  border: 1px solid red;
  border-radius: 8px;
  display: flex;


}
.subscription-wrapper{
 flex-direction: column;
  gap:20px;
  padding: 20px;
}

.help-block{
  color:red;
}
.imageshowsize {
  height: 100px;
  width: 100px;
  border-width: 0px;
  border-color: #ffffff;
}
.imageshowsizeborder {
  height: 100px;
  width: 100px;
  
 border:1px solid #f4fcf3 ;
  border-radius: 4px;
}
.aligniconborder{
 position:relative;
 bottom:50px;
 right:32px;
  
  }

.imagevariantsize {
  height: 40px;
  width: 40px;
}

.rdt_TableRow {
  .drag-handle {
    cursor: move !important;
  }
}

label.label input[type="file"] {
  position: absolute;
  top: -1000px;
}

.label {
  cursor: pointer;

  border-radius: 5px;
  padding: 5px 15px;
  margin: 5px;
  background: #ffffff;
  display: inline-block;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid black;
  border-radius: 50% !important;
  margin-right: 0.5rem;
}

.dot-filled {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50% !important;
  margin-right: 0.5rem;
}

.valeo-badge {
  color: black;
  background-color: lightgrey;
  padding: 0.2rem 0.5rem;
  border-radius: 20px;
  font-size: 0.75rem;
}

.buttonfill {
  background: #247A81;
  color: #ffffff;
  padding: 8px;
}

.notetext {
  font-size: 12px;

}

.countrystyle {
  font-size: 26px;
  color: #07141A;
  font-weight: 600;
}

.imagecountryblock {
  height: 150px;
  width: 150px;
  background: #F4FCF3;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullcard {
  height: 50%;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;

}

.countryfirstblock {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
}

.countrysecondblock {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 30px;
}

.headingcolor {
  color: #07141A;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-family: Work Sans;
}

.savebtn {
  padding: 10px 30px;
  border-radius: 20px;

  color: #ffffff;
  border-width: 0px;
  background-color: #247A81;
}

.donebtn {
  padding: 5px 10px;
  border-radius: 10px;

  color: #ffffff;
  border-width: 0px;
  background-color: #247A81;
}

.alignright {
  display: flex;
  flex-direction: row-reverse;
}

.aligncenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageViewBlock{
 border: 1px solid #D9D9D9;
}
.aligncenterImage{
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}
.alignrowpassword {
  display: flex;
  flex-direction: row;
  
  
  box-sizing: border-box;
}
.noFocusOutline:focus {
  outline: none;
  border:none
}