.styled-table {
    border-collapse: collapse;
    width: 100%;
 
   
  }
  .currency{
    text-align: right;
      }
      .pricing-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
  // .price{
  //   text-align: right;
  // }
  .styled-table td .price{
    text-align: right;
  }
  .styled-table th,
  .styled-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }


  .styled-table input{
    width:100%;
    
  }

  .table-container{
    max-height: 200px; /* Adjust the max height as per your requirements */
    overflow-y: auto;
  }
  .scrollable-tbody {
    // display: block;
  }
  
  .styled-table th {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
  }
  .styled-table th, .styled-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  