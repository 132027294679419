.exportPopUpContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    .popUp{
        border-radius: 16px;
        background-color: #FFF;
        padding: 10px;
        width: 400px;
        .exportHeading{
            font-size: 20px;
            font-weight: 600;
            color: #000;
        }
        .optionContent{
            display: flex;
            flex-direction: column;
            .option{
                display: flex;
                gap: 5px;

            }
        }
        .error-msg{
            margin-top: 5px;
            color: red;
        }
        .footer-div{
            display: flex;
            flex-direction:row-reverse;
            gap: 10px;
            margin-top: 10px;
            .cancel-btn{
                color: #000;
                background-color: #FFF;
                padding: 5px;
                text-align: center;
                border-radius: 10px;
                border: 2px solid #000;
                cursor: pointer;
            }
            .export-btn{
                color: #FFF;
                background-color: #000;
                padding: 5px;
                text-align: center;
                border-radius: 10px;
                cursor: pointer;
            }
        }
       
    
    }
}
