.dashboard {
  height: calc(100vh - 50px);
  width: 100%;
  left: 0;
  position: absolute;
  top: 50px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .welcome {
    text-align: center;
    max-width: 80%;
    margin-bottom: 2%;

    .welcome-image {
      width: 100%;
      max-width: 22rem;
      margin: 0 auto;
      display: block;
    }

    .welcome-text {
      margin: 3rem 0 1rem;
    }

    .welcome-description {
      max-width: 80%;
      display: block;
      margin: 0 auto;
      font-size: 1.2rem;
    }

    .action-button {
      margin-top: 1.6rem;
      border-radius: 25px;
      padding: 8px 20px;
    }
  }
}
