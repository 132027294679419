.button {
  color: #fff;
  font-weight: $font-weight-semibold;

  @include hover-fade;

  &:hover {
    color: #fff;
  }
}
.borderlessbutton{
  margin: 5px;
border-color: white;
border-radius: 5px;
background-color: #ffffff;
}
.spacebutton{
  display:flex;
  margin: 5px;
  width:20vw;
  justify-content: space-evenly;
}
.button-blue {
  background: #32445b;
  background-color: #32445b;
  color: #fff !important;
}
.button-green {
  background: $gradient-green;
  background-color: $valeo-green;
}

.button-purple {
  background: $gradient-purple;
  background-color: $valeo-purple;
}

.button-pink {
  background: $gradient-pink;
  background-color: $valeo-pink;
}

.button-dark {
  background: $gradient-dark;
  background-color: $valeo-dark;
}

.add-button {
  border: 1px solid var(--blue);
  color: var(--blue);
  margin-right: 15px;

  &:hover {
    background: var(--blue);
    color: #ffffff;
  }
}