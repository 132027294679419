.status-val {
    font-size: 12px;

    .status-min-val {
        position: relative;
        top: 12px;
        float: left;
        z-index: 3;
    }

    .status-max-val {
        position: relative;
        float: left;
        top: 12px;
    }
}

.result {
    position: absolute;

    .marker {
        float: left;
        top: -20px;
        position: relative;
        left: -5px;
        transition: '1s'
    }
}