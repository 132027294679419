.login-container {
  width: 100vw;
  height: 100vh;
  background: $gradient-dark;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-form {
    width: 300px;
    padding: 1.5rem;
    background: #fff;
    display: block;
    margin: 0 auto;
    box-shadow: $shadow-1;
    border-radius: 5px;

    .valeo-logo {
      max-width: 60%;
      margin: 0 auto;
      display: block;
      margin-bottom: 2.2rem;
    }

    .login-button {
      color: #fff;
      background: $gradient-green;
      margin-top: 2rem;
      font-size: 0.9rem;

      &:disabled {
        opacity: 0.7;
      }

      .valeo-loader {
        color: #fff !important;
        margin-top: 3px;
      }
    }

    .alert {
      font-size: 13px;
    }
  }
}
