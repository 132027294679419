.sidebar {
  width: $sidebar-width;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background: #247A81;
  overflow-y: auto;
  transition: all 0.3s;
  overflow-x: hidden;
  z-index: 99;
  animation: showup 0.3s;
  box-shadow: inset -10px 0 15px -5px rgba(0, 0, 0, 0.35);
  scrollbar-width: thin;
  scrollbar-color: #00c4d7 #00c4d7 !important;

  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: $valeo-blue;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $valeo-blue;
    opacity: 0.5;
  }

  .logo-container {
    margin: 2rem auto 3rem;
    text-align: center;
    position: relative;
    width: $sidebar-width;

    .menu-icon {
      color: #fff;
      position: absolute;
      top: 0.9rem;
      left: 0.7rem;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        color: $valeo-green;
      }
    }

    .logo {
      color: #fff;
      font-size: 2rem;
      transition: all 0.3s;
      opacity: 1;
      font-weight: $font-weight-bold;

      .valeo-logo {
        max-width: 50%;
      }
    }
  }

  .nav-links {
    padding: 0 25px 5rem;

    .nav-content {
      display: block;
      // width: calc(#{$sidebar-width} - 12px);

      .nav-link {
        font-size: 0.73rem;
        padding: 0;
        color: #fff;
        font-weight: 600;
        position: relative;
      }

      .nav-header {
        text-transform: uppercase;
        transition: all 0.3s;
        border-radius: 2px;
        color: #f8f8f8;
        padding: 0 0.52rem;
        height: 36px;
        line-height: 36px;
        padding-left: 7px;


        .nav-text {
          transition: all 0.3s;
          pointer-events: none;

          .text {
            transition: all 0.3s;
          }
        }

        span {
          cursor: default !important;
        }

        .arrow-down {
          height: 10px;
          float: right;
          margin-top: 12px;
          transition: all 0.3s;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.3);
        }

        &.active {
          background: linear-gradient(to right, #2ebc98, #00c4d7);
          color: #fff;

          .notification {
            opacity: 0;
          }
        }

        .nav-icon {
          margin-right: 0.8rem;
          font-size: 1.5em;
          transform: translateY(-1px);
          transition: all 0.3s;
        }
      }

      .nav-header[aria-expanded="true"] {
        background: rgba(255, 255, 255, 0.4);
        color: #fff;

        .arrow-down {
          transform: rotateX(180deg);
        }
      }

      .sub-links {
        padding: 6px 0;
        transition: all 0.3s;
        max-height: 255px;

        a {
          padding: 3px 5px 3px 25px;
          display: block;
          transition: all 0.3s;
          font-size: 13px;
          color: #eee;
          border-left: 2px solid rgba(255, 255, 255, 0);
          margin-top: 6px;

          &:not(.active):hover {
            color: #fff;
          }

          &.active {
            color: $valeo-blue;
            border-left: 2px solid $valeo-blue;
            text-shadow: 0.01px 0.1px $valeo-blue;
          }
        }
      }

      &:not(:last-of-type):after {
        content: "";
        width: 100%;
        // height: 1px;
        display: block;
        margin: 10px 0;
        background: rgba(255, 255, 255, 0.15);
        transition: all 0.3s;
      }

      .notification {
        display: inline-block;
        margin-bottom: 5px;
        margin-left: 5px;
        background: #00c4d7;
        border-radius: 50%;
        height: 5px;
        width: 5px;
        transition: all 0.3s;
      }
    }
  }

  &.collapsed {
    width: $sidebar-small-width;

    .logo-container {
      .logo {
        opacity: 0;
        visibility: hidden;
      }
    }

    .sub-links {
      opacity: 0;
      max-height: 0 !important;
      pointer-events: none;
    }

    .nav-text {
      .text {
        opacity: 0;
      }

      .notification {
        position: absolute;
        left: 27px;
        top: 9px;
      }
    }

    .nav-content {
      &:not(:last-of-type):after {
        width: 2.1rem;
        margin: 10px 0;
      }

      .nav-header {
        margin-left: -6px;
        padding-left: 0.9rem;
        border-radius: 0;

        &.accordion-header {
          .nav-icon {
            transform: translateY(-5px);
          }
        }

        .arrow-down {
          position: absolute;
          left: 1.2rem;
          margin-top: 1.5rem;
          width: 0.5rem;
        }
      }

      .nav-header[aria-expanded="true"] {
        margin-bottom: -0.7rem;

        .arrow-down {
          transform: rotateX(180deg);
        }
      }
    }
  }
}