$contacts-width: 235px;
$header-height: 50px;
$footer-height: 50px;

.chat {
  height: calc(100vh - 50px);
  width: 100%;
  left: 0;
  position: absolute;
  top: 50px;
  background: rgba(46, 188, 152, 0.2);
  border-radius: 0;
  padding: 3rem 6rem;

  .chat-container {
    width: 100%;
    height: 100%;
    box-shadow: $shadow-5;
    border-radius: 4px;
    overflow: hidden;
    display: flex;

    .chat-alert {
      text-align: center;
      color: rgb(150, 150, 150);

      &.window-alert {
        height: 100%;
        width: calc(100% - #{$contacts-width});
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(245, 245, 245);

        &.error {
          color: $valeo-red;
        }
      }
    }

    .contacts-container {
      height: 100%;
      width: $contacts-width;
      display: inline-block;
      background: rgba(255, 255, 255, 0.85);
      border-right: 1px solid rgb(231, 231, 231);

      .contacts-header {
        width: $contacts-width;
        height: $header-height;
        position: absolute;
        border-right: 1px solid rgb(231, 231, 231);
        display: flex;
        align-items: center;
        justify-content: center;

        .heading {
          font-weight: normal;
          margin: 0;
          cursor: default;
        }
      }

      .contacts {
        margin-top: $header-height;
        width: 100%;
        height: calc(100% - #{$header-height});
        padding: 15px 0;
        overflow-y: auto;
        overflow-x: hidden;

        .contact {
          width: 100%;
          padding: 0px 15px;
          height: 46px;
          transition: all 0.3s;
          cursor: default;
          position: relative;

          .dp {
            width: 26px;
            height: 26px;
            background: rgb(155, 155, 155);
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
            vertical-align: middle;
            display: inline-block;
          }

          .name {
            color: rgb(134, 134, 134);

            vertical-align: middle;
            display: inline-block;
            height: 100%;
            padding-top: 13px;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 70%;
            overflow: hidden;
          }

          &.active {
            background: rgba(46, 188, 152, 0.16);

            .name {
              color: #29a989;
            }
          }

          &:not(.active) {
            cursor: pointer;
          }

          &:not(.active):hover {
            background: rgba(46, 188, 152, 0.06);
          }

          .notification {
            position: absolute;
            top: 14px;
            right: 15px;
            background: $valeo-green;
            height: 17px;
            min-width: 17px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            animation: showup 0.3s;
          }
        }

        &:hover {
          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.3);
          }
        }

        &::-webkit-scrollbar {
          width: 2px !important;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.15);
          transition: all 0.3s;
        }
      }
    }

    .chat-window {
      height: 100%;
      width: calc(100% - #{$contacts-width});
      display: inline-block;
      position: relative;

      .chat-header {
        width: 100%;
        height: $header-height;
        position: absolute;
        border-bottom: 1px solid rgb(231, 231, 231);
        background: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;

        .heading {
          color: rgb(122, 122, 122);
          margin: 0;
          cursor: default;
        }
      }

      .chat-box-container {
        margin-top: $header-height;
        width: 100%;
        height: calc(100% - #{$header-height + $footer-height});
        background: #fff;

        .goto-bottom {
          background: rgb(122, 122, 122);
          transition: all 0.3s;
          box-shadow: $shadow-4;
          position: absolute;
          right: 30px;
          bottom: 80px;
          height: 26px;
          width: 26px;
          border-radius: 50%;
          cursor: pointer;
          animation: showup 0.3s;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon-arrow {
            font-size: 22px;
          }

          &:hover {
            opacity: 0.8;
          }
        }

        .chat-box {
          background: #fff;
          padding: 20px;
          transition: all 0.3s;
          animation: showup 0.3s;
          height: 100%;
          width: 100%;
          overflow-y: auto;

          .date {
            text-align: center;
            margin: 40px 0 15px;
            font-weight: normal;
            font-size: 12px;
            color: rgb(172, 172, 172);
            cursor: default;

            &:first-of-type {
              margin-top: 0;
            }
          }

          .message {
            display: flex;
            position: relative;
            width: 100%;
            transition: all 0.3s;
            cursor: default;

            .text-container {
              display: inline-block;
              animation: showup 0.3s;
              max-width: 90%;

              .text {
                display: block;
                padding: 6px 10px;
                border-radius: 4px;
                position: relative;
                margin: 0;
                overflow-wrap: break-word;
              }

              .timestamp {
                color: rgb(168, 168, 168);
                font-size: 10px;
                overflow: hidden;
                height: 0;
                opacity: 0;
                transition: all 0.3s;
                display: flex;
                align-items: center;

                .status-icon {
                  font-size: 14px;
                  margin-right: 3px;

                  &.failed {
                    color: $valeo-red;
                  }
                }
              }

              &:hover {
                .timestamp {
                  height: 24px;
                  opacity: 1;
                }
              }
            }

            &.left {
              .text-container {
                .text {
                  background: #e9e9e9;
                  color: rgb(95, 95, 95);

                  .arrow {
                    content: " ";
                    position: absolute;
                    width: 0;
                    height: 0;
                    left: -10px;
                    right: auto;
                    top: 0;
                    bottom: auto;
                    border: 10px solid transparent;
                    border-top-color: #e9e9e9;
                    // animation: showup 0.3s;
                  }
                }

                .timestamp {
                  .status-icon {
                    display: none;
                  }
                }
              }
            }

            &.right {
              .text-container {
                margin-left: auto;

                .text {
                  background: $valeo-green;
                  color: #fff;

                  .arrow {
                    content: " ";
                    position: absolute;
                    width: 0;
                    top: 0;
                    height: 0;
                    right: -10px;
                    bottom: auto;
                    border: 10px solid transparent;
                    border-top-color: $valeo-green;
                    // animation: showup 0.3s;
                  }
                }

                .timestamp {
                  float: right;
                }
              }
            }
          }

          .message:last-of-type,
          .message.pending,
          .message.failed {
            .text-container {
              .timestamp {
                height: 24px;
                opacity: 1;
              }
            }
          }

          .message.left+.message.right,
          .message.right+.message.left {
            margin-top: 20px;
          }

          .message.left+.message.left,
          .message.right+.message.right {
            margin-top: 2px;

            .arrow {
              display: none;
            }
          }

          &:hover {
            &::-webkit-scrollbar-thumb {
              background: $valeo-green;
              background: rgba(0, 0, 0, 0.3);
            }
          }

          &::-webkit-scrollbar {
            width: 2px !important;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.15);
          }
        }
      }

      .chat-footer {
        width: 100%;
        height: $footer-height;
        position: absolute;
        bottom: 0;
        background: #fff;
        border-top: 1px solid rgb(231, 231, 231);
        display: flex;
        justify-content: center;
        align-items: center;

        .chat-input {
          width: calc(100% - 60px);
          min-height: 35px;
          border-radius: 5px;
          padding: 0 18px;
          border: none;
          background: #f0f0f0;
          transition: all 0.3s;
          outline: none;
          box-sizing: border-box;
          margin-right: 5px;
          vertical-align: baseline;
          max-height: 35px;
          overflow-y: auto;
          overflow-x: hidden;
          color: #505050;

          &:hover,
          &:focus {
            background: #ececec;
          }

          &::-webkit-scrollbar {
            width: 8px !important;
          }

          &::-webkit-scrollbar-thumb {
            background: $valeo-blue;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: $valeo-blue;
            opacity: 0.5;
          }

        }

        .send-button {
          cursor: pointer;
          transition: all 0.3s;
          background: $valeo-green;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          .send-icon {
            font-size: 18px;
          }

          &:hover {
            opacity: 0.8;
          }
        }

        .chat-disabled-text {
          color: #acacac;
        }

        &.disabled {
          pointer-events: none;

          .send-button,
          .chat-input {
            display: none;
          }
        }
      }
    }
  }
}