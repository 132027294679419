.filter-section{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .filter-box{
        display: flex;
        align-items: center;
        gap:10px;
    }

}

.select-filter{
   
    width:133px;
    background: #FFF;
    color: #07141A;

font-family: Work Sans;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;

height:38px;
h6{
margin-top: -14px;
}
.filter{
padding-left: 10px;
cursor: pointer;
}
}

@media (max-width:1345px){
.filter-section{
    margin-bottom: 130px;
}
}