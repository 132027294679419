.media-wrapper{
    label{
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 6px;
        padding-top: 5px;
    }
    
    .action-btn{
        padding: 5px 10px;
        border-radius: 19px;
        background-color: #247A81;
        color: #ffffff;
        border-width: 0px;
        width: 240px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
    }

    .media-container{
        display: flex;
        gap: 20px;
        align-items: center;
    }

    .primary-txt{
        display: flex;
        gap: 10px;
        align-items: center;
        color: #247A81;
    }

    .image-wrapper{
        overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
        margin-top: 20px;
        display: flex;
        gap: 30px;
        
        .image-card-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            .primary-txt{

            }

            .img-container{
                background-color: #F4FCF3;
                height: 94px;
                width: 110px;
                border: 1px solid #D9D9D9;
                border-radius: 8px;
                margin-top: 10px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            
            .img-action-container{
                margin-top: 15px;
                display: flex;
                gap: 15px;
                img{
                    height: 20px;
                    width: 20px;
                    cursor: pointer;
                }

            }
        }

    }
    .image-wrapper::-webkit-scrollbar {
        display: none;
      }

}