.footer-btn-container{ 
    background: white;
    width: 100%;
    position: fixed;
    bottom: 0px;
    height: 55px;
    z-index: 9999999999;
    width: calc(100% - 220px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: -2px -2px 6px rgba(0, 0, 0, 0.1) !important;
    border: none !important;
    .save-btn{
        background-color: #247A81;
        color: white;
        width: 114px;
        text-align: center;
        margin-right: 100px;
        padding: 5px;
        border-radius: 19px;
        width: 128px;
        cursor: pointer;
    }
}
.searchTagDiv{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    color: #07141A;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 100px;
    .tagTxt{
    }
    input{
    
    }
}