.table{
    margin-bottom: 0px !important;
}
td{
    padding-top: 10px;
    padding-bottom: 10px;
}

.search-spinner{
    border: 5px solid #f3f3f3;
    border-top: 5px solid #247A81;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}
    
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}