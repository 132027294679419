.collection-name{
    background-color: #247A81;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    text-align: center;
    border-radius: 19px;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 15px;
    width:fit-content;
    font-size: 12px;
    font-weight: 500;

}