.importPopUpContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    .PopUp{
        border-radius: 16px;
        background-color: #FFF;
        padding:10px 35px;
        width: 580px;
       
        .PopUpBody{
        //    display: flex;
        //    justify-content: center;
        //    align-items: center;
        //    height: 100px;
            // border: 2px dotted #000;
            .selectContainer{
                .productDetailssection{
                    .productTxt{
                        color: #07141A;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        span{
                            font-size: 15px;
                            border-bottom: 2px solid;
                            color: blue;
                            cursor: pointer;
                        }
                       
                    }
                    .closeBtn{
                        display: flex;
                        align-items: center;
                        border-radius: 19px;
                        cursor: pointer;
background: rgba(230, 231, 231, 0.30);
color: #07141A;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 2px 15px;
img{
    width: 25px;
    height: 25px;
}
                    }
                    .productDetailsSelectDiv{
                        border-radius: 8px;
                        background: #F6F7F7;
                        margin-top: 20px;
                        display: flex;
                        // justify-content: center;
                        align-items: center;
                        gap: 10px;
                        padding: 17px 19px;
                        color: #07141A;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
                    }
                }
                .selectCountySection{
                   .selectCountyTxt{
                    color: #07141A;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-top: 15px;
                    span{
                        font-size: 15px;
                        border-bottom: 2px solid;
                        color: blue;
                        cursor: pointer;
                    }
                   } 
                   .countyListDiv{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 22px;
                    margin-top: 15px;
                    .selectCountyInputDiv{
                        border-radius: 8px;
                        background: #F6F7F7;
                        width: 242px;
                        display: flex;
                        padding: 17px 19px;
                        // justify-content: center;
                        align-items: center;
                        gap: 10px;
                        color: #07141A;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
                }
               
            }

            .importHeading{
                color: #07141A;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-top: 20px;
            }

            .footer-div{
                display: flex;
                // flex-direction:row-reverse;
                gap: 24px;
                margin-top: 10px;
                .add-btn{
                    // padding: 5px 10px;
                    text-align: center;
                    border-radius: 8px;
                    border: 1px solid #D9D9D9;  
                    background: #F4FCF3;
                    // padding: 20px 76px;
                    // padding-top: 20px;
                    // padding-bottom: 20px;
                    width: 260px;
                    height: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // cursor: pointer;
                    .importInput{
                       display: none; 

                    }
                    .add-file-btn{

                        cursor: pointer;
                        padding: 8px;
                        border-radius: 19px;
                        background: #247A81;
                        color: #FFF;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }
                .import-btn{
                    background: #07141A;
                    width: 240px;
                    height: 44px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #FFF;
                    cursor: pointer;
                    border-radius: 19px;
                    margin-top: 34px;
                }
                .cancel-btn{
                    color: #000;
                    background-color: #FFF;
                    padding: 5px;
                    text-align: center;
                    border-radius: 10px;
                    border: 2px solid #000;
                    cursor: pointer;
                }
            }
        }
       
    }
    .importErrorPopUp{
   
    }
}

