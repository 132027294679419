    .header-txt{
        font-size: 14px;
        font-weight: 600;
    }

    .select-container{
        margin-top: 10px;
    }
    .frequently-bought-list-item{
        
        margin-top: 15px;
        .list-item{
            display: flex;
            flex-direction: column;
            margin-top: 15px;
            .list-info{
                display: flex;
                gap: 14px;
                border: 1px solid #E6E7E7;
                padding: 10px;
                border-radius: 4px;
            }
            .delete-container{

                display: flex;
                flex-direction: row;
                gap: 5px;
                margin-top: 4px;
                cursor: pointer;
                justify-content: end;
                .delete-txt{
                    font-size: 12px;
                    color: #247A81;
                }
                img{
                    height: 18px;
                }
                
            }
        }
    }