$valeo-green: #2ebc98;
$valeo-blue: #00c4d7;
$valeo-pink: #df9cbe;
$valeo-purple: #9279a0;
$valeo-dark: #32445b;
$valeo-grey: #f5f7f5;
$valeo-black: #1c1d23;
$valeo-grey-2: #718192;
$valeo-green-light: #eef9f7;
$valeo-green-light2: #eaf8f5;
$valeo-blue-light: #e6f9fb;
$valeo-red: #dc3545;
$valeo-red-light: #fbe9eb;
$shadow-1: 0px 3px 6px rgba(0, 0, 0, 0.16);
$shadow-2: 0px 6px 30px rgba(0, 0, 0, 0.16);
$shadow-3: 0px 3px 6px rgba(0, 0, 0, 0.1);
$shadow-4: 0px 3px 8px rgba(41, 7, 7, 0.25);
$shadow-5: 0px 6px 40px rgba(0, 0, 0, 0.15);
$shadow-6:0px 4px 8px rgba(0, 0, 0, 0.279);

$gradient-green: linear-gradient(to bottom, #2ebc98, #00c4d7);
$gradient-pink: linear-gradient(to bottom, #df9cbe, #b492cb);
$gradient-purple: linear-gradient(to bottom, #747baa, #9279a0);
$gradient-dark: linear-gradient(to bottom, #32445b, #4e5674);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;

$sidebar-width: 240px;
$sidebar-small-width: 2.9rem;
