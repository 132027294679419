.product-details-wrapper{

    .section-title{
        font-size: 18px;
        font-weight: 600;
    }
    label{
        font-size: 14px;
        font-weight: 500;
        color: #07141A;
        padding-bottom: 6px;
        padding-top: 5px;
    }

    .section-gap{
        margin-top: 20px;
        width: 100%;
    }

}
.bestSeller{
    margin-top: 20px;
    display: flex;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #07141A;
}